<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="formatosHeader"
            class="elevation-1"
            :items="formatosImpresionOrdenesPago"
            :search="search"
            :loading="isLoading"
            item-key="formatoImpresionId"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left: 80px">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left: 80px">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left: 80px"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn color="primary" @click="newFormato" class="to-right">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editFormato(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar formato</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDeleteFormat(item.formatoImpresionId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar formato</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>

    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteFormat()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "FormatosImpresionOrdenesPago",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda
  },
  data: () => ({
    title: enums.titles.FORMATOS_IMPRESION_ORDENES_DE_PAGO,
    optionCode: enums.webSiteOptions.FORMATOS_IMPRESION_ORDENES_DE_PAGO,
    searchIcon: enums.icons.SEARCH,
    openDeleteDialog: false,
    routeToGo: "ConfiguracionOrdenesPago",
    formatosImpresionOrdenesPago: [],
    search: "",
    isLoading: false,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    titleDelete: "¿Eliminar formato?",
    expanded: [],
    formatosHeader: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
        sortable: false
      },
      {
        text: "Tipo de impresión",
        value: "tipoImpresionNom",
        align: "start",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end"
      }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    showExpand: false,
    showHelp: false,
    showIcon: true
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadFormatos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchFormatoImpresionOP: "proveedores/fetchFormatoImpresionOP",
      deleteFormatoImpresionOP: "proveedores/deleteFormatoImpresionOP"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_FORMATO_IMPRESION_ORDENES_PAGO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_FORMATO_IMPRESION_ORDENES_PAGO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_FORMATO_IMPRESION_ORDENES_PAGO:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadFormatos() {
      this.isLoading = true;
      try {
        const formatos = await this.fetchFormatoImpresionOP();
        this.formatosImpresionOrdenesPago = formatos;
        if (res.status == 200) {
          this.isLoading = false;
        }
      } catch {
        this.isLoading = false;
      }
    },
    editFormato(item) {
      this.$router.push({
        name: "EditFormatoImpresionOrdenesPago",
        params: { formato: item }
      });
    },
    openDeleteFormat(id) {
      this.idToDelete = id;
      this.openDeleteDialog = true;
    },
    async deleteFormat() {
      const res = await this.deleteFormatoImpresionOP(this.idToDelete);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.openDeleteDialog = false;
        this.loadFormatos();
      }
    },
    newFormato() {
      this.$router.push({ name: "EditFormatoImpresionOrdenesPago" });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    }
  }
};
</script>

<style lang="scss" scoped></style>
